@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        @apply box-border;
    }

    /* Ensure all pseudo-elements (before/after) also respect border-box sizing */
    *::before,
    *::after {
        @apply box-border;
    }
}

:root {
    --color-brand-primary: #18181b;
    --color-brand-secondary: #71717a;
    --color-brand-tertiary: #b8b8b8;
}

/* @media (prefers-color-scheme: dark) {
    :root {
        --color-brand-primary: #09090b;
        --color-brand-secondary: #71717a;
        --color-brand-tertiary: #b8b8b8;
    }
} */

body {
    @apply text-brand-primary bg-white font-sans text-base;
}

html,
body {
    @apply h-full;
}

.diamond-gradient {
    width: 720px;
    height: 720px;
    background: radial-gradient(
        50% 50% at 50% 50%,
        #7fcb43 21.5%,
        #6cb0ee 74%,
        #f55fbe 90%,
        #f9f7f4 100%
    );
    /* transform: rotate(45deg); */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%); /* This hides the top half */
}

.inner-content {
    /* transform: rotate(-45deg); */
    color: white;
    font-size: 16px;
}

.card-container {
    @apply flex  justify-between gap-2 mb-2;
}
.card-container > * {
    @apply w-full;
}
html {
    scroll-behavior: smooth;
}

@keyframes gradientFill {
    0% {
        width: 0%;
        /* filter: brightness(0.8); */
    }
    100% {
        width: 100%;
        /* filter: brightness(1.5); */
    }
}

@keyframes leftCircleFill {
    0% {
        background-color: lightgray;
    }
    100% {
        background-color: var(--start-color); /* Dynamic color */
    }
}
@keyframes rightCircleFill {
    0% {
        background-color: lightgray;
    }
    100% {
        background-color: var(--end-color); /* Dynamic color */
    }
}

.animate-left-circle {
    animation: leftCircleFill 500ms ease-in-out forwards;
    animation-delay: 0s;
}
.animate-gradient {
    animation: gradientFill 3s ease-in-out forwards;
    animation-delay: 500ms;
}

.animate-right-circle {
    animation: rightCircleFill 500ms ease-in forwards;
    animation-delay: 3s; /* Start after gradient animation finishes */
}

.no-scroll {
    overflow: hidden;
    height: 100%;
}
